import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CDNService,
  ContentModel,
  PageModel,
} from '@murdough-solutions/cms-common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'CustomPurchasePage',
  templateUrl: './purchase-page.html',
  styleUrls: ['./purchase-page.scss'],
})
export class CustomPurchasePage implements AfterViewInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly route: ActivatedRoute,
    public readonly router: Router,
    public readonly cdn: CDNService
  ) {}

  ngAfterViewInit(): void {
    const css = this.document.createElement('link');
    css.rel = 'stylesheet';
    css.href = 'https://app.runstella.com/widget/index.css';
    this.document.head.appendChild(css);

    const script = this.document.createElement('script');
    script.src = 'https://app.runstella.com/widget/index.js';
    this.document.head.appendChild(script);
  }

  public readonly page$ = this.route.data.pipe(
    map(({ page_model }) => page_model as PageModel)
  );

  public readonly content$ = this.route.data.pipe(
    map(({ content_model }) => content_model as ContentModel)
  );

  scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
