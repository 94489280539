<cms-loading-overlay></cms-loading-overlay>
<header>
    <div class="container">
        <div class="row my-3">
            <div class="col-md-4 order-md-2">
                <a routerLink="/">
                    <img src="/cdn/assets/e1b9f6c7-ac8b-422f-9602-df1250b482eb.webp" class="img-fluid mx-auto d-block" />
                </a>
            </div>
            <div class="col-md-4 order-md-1 text-center">
                <div class="d-table w-100 h-100">
                    <div class="d-table-cell align-middle text-center">
                        <h4 class="my-3">Call us for a Free Quote<br/>(254) 829-2411</h4>
                    </div>
                </div>
            </div>
            <div class="col-md-4 order-md-3">
                <div class="d-table w-100 h-100">
                    <div class="d-table-cell align-middle text-center">
                        <p class="my-3">
                            <a class="btn btn-default" [routerLink]="['/rentals']">Reserve Now!</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<section id="main-content">
    <router-outlet></router-outlet>
</section>
<footer class="py-3">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="d-table w-100 h-100">
                    <div class="d-table-cell align-middle text-center">
                        <div class="my-3">
                            <cms-menu id="9891098a-1385-4f62-abb5-2f8d603e2d41" [component_resolver]="component_resolver"></cms-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-center text-md-right">
                <img class="img-fluid my-3" src="/cdn/assets/ab350980-ee0b-4237-94c0-e50c18988484.webp" />
            </div>
        </div>
    </div>
</footer>