import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormCompletionFieldModel, FormCompletionModel, TemplateStorageType } from '@murdough-solutions/cms-common';
import { isDate, parse } from 'date-fns';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GoogleTagManagerService } from './google-tag-manager.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public formFieldUpdated: EventEmitter<FormCompletionFieldModel> = new EventEmitter<FormCompletionFieldModel>();

  constructor(private http: HttpClient, private router: Router, private gtm: GoogleTagManagerService) {

  }

  public CompleteForm(form_id: string, model: FormCompletionModel): Observable<FormCompletionModel> {

    model.route = this.router.url;

    return this.http.put<FormCompletionModel>(environment.service_base + 'api/v1/client/forms/' + form_id, model).pipe(
      tap(data => {
        this.gtm.FormCompletion(data);
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err.error?.message ?? err.message);
      }));

  }

  public BuildFormGroup(fields: Array<FormCompletionFieldModel>): UntypedFormGroup {
    const controls = {};

    fields = fields.filter(f => !f.remove);

    for (const field of fields) {
      field.visible = true;

      this.add_control(field, controls);
    }

    const form_group = new UntypedFormGroup(controls);

    for (const field of fields) {
      this.process_control(field, form_group, controls);
    }

    return form_group;
  }

  private get_validators(field: FormCompletionFieldModel): Array<ValidatorFn> {
    const validators = new Array<ValidatorFn>();

    if (field.visible && field.required) {
      validators.push(field.storage_type === TemplateStorageType.Boolean ? Validators.requiredTrue : Validators.required);
    }

    if (field.storage_type === TemplateStorageType.Email) {
      validators.push(Validators.email);
    }

    return validators;
  }

  private add_control(field: FormCompletionFieldModel, controls: { [key: string]: any }): void {
    let value: any;
    switch (field.storage_type) {
      case TemplateStorageType.Option:
        value = field.option;
        break;
      case TemplateStorageType.OptionPrimative:
        value = field.option?.form_field_option_id;
        break;
      case TemplateStorageType.Province:
        value = field.province;
        break;
      case TemplateStorageType.Date:
        value = field.value_date;
        break;
      case TemplateStorageType.Numeric:
        value = field.value_numeric;
        break;
      case TemplateStorageType.Boolean:
        value = field.value_boolean;
        break;
      case TemplateStorageType.File:
        value = field.file;
        break;
      default:
        value = field.value_string;
        break;
    }

    controls[field.form_field_id] = new UntypedFormControl(value, this.get_validators(field));

    if (field.children) {
      for (const child of field.children) {
        this.add_control(child, controls);
      }
    }
  }

  private process_control(field: FormCompletionFieldModel, form_group: UntypedFormGroup, controls: { [key: string]: any }): void {
    const control = controls[field.form_field_id];

    control.valueChanges.subscribe((val: any) => {

      switch (field.storage_type) {
        case TemplateStorageType.OptionPrimative:
          if (field.options) {
            field.option = field.options.find(f => f.form_field_option_id === val);
          }
          break;
        case TemplateStorageType.Option:
          field.option = val;
          break;
        case TemplateStorageType.Province:
          field.province = val;
          break;
        case TemplateStorageType.Date:
          if (isDate(val))
          {
            field.value_date = val;
          }
          else if (field.value_date)
          {
            field.value_date = parse(val, 'yyyy-MM-dd', new Date());
          }
          break;
        case TemplateStorageType.Numeric:
          field.value_numeric = val;
          break;
        case TemplateStorageType.Boolean:
          field.value_boolean = val;
          break;
        case TemplateStorageType.File:
          field.file = val;
          break;
        default:
          field.value_string = val;
          break;
      }

      this.process_visibility(field, form_group);

      this.formFieldUpdated.emit(field);
    });

    if (field.children) {
      for (const child of field.children) {
        this.process_control(child, form_group, controls);
      }
    }
  }

  private process_visibility(parent: FormCompletionFieldModel, form_group: UntypedFormGroup): void {

    if (parent.children) {
      for (const child of parent.children) {
        const control = form_group?.get(child.form_field_id);
        if (control) {
          control.clearValidators();
          control.setValidators(this.get_validators(child));
          control.updateValueAndValidity();
        }
      }
    }
  }

  public Validate(form_group: UntypedFormGroup, form_completion: FormCompletionModel): boolean {
    form_completion.route = this.router.url;
    form_group.markAllAsTouched()

    return form_group.valid;
  }

}
