import { CustomTestimonials } from './testimonials/testimonials';
import { CustomContactForm } from './contact-form/contact-form';
import { CustomTextareaFormField } from './textarea-form-field/textarea-form-field';
import { CustomNumericFormField } from './numeric-form-field/numeric-form-field';
import { CustomFooterMenu } from './footer-menu/footer-menu';
import { CustomWhoWeArePage } from './who-we-are-page/who-we-are-page';
import { CustomRentalsPage } from './rentals-page/rentals-page';
import { CustomPurchasePage } from './purchase-page/purchase-page';
import { CustomHomePage } from './home-page/home-page';
import { CustomBasicPage } from './basic-page/basic-page';
import { CustomHoneypot } from './honeypot/honeypot';
import { CustomEMailFormField } from './e-mail-form-field/e-mail-form-field';
import { CustomDropdownField } from './dropdown-field/dropdown-field';
import { CustomTextFormField } from './text-form-field/text-form-field';
import { CustomDateInput } from './date-input/date-input';

import { Type } from '@angular/core';
import { LilyPadPageComponent } from './lily-pad-page/lily-pad-page.component';

export function component_resolver(template_id: string): Type<any> | undefined {
  switch (template_id) {
    case '1ce3fe7e-dd8f-4293-a6b3-01be7652b53b':
      return CustomTestimonials;
    case 'aa3fd0c5-0996-4fa7-8f69-f28e01189ed4':
      return CustomContactForm;
    case '5f2ca476-48c9-4c04-a03a-16de14138021':
      return CustomTextareaFormField;
    case '76302336-74fe-4fea-853b-1a0fe1417d5c':
      return CustomNumericFormField;
    case '8e458f31-5667-4335-95d2-b1bc2e7d2145':
      return CustomFooterMenu;
    case 'aadf4820-8f1e-4b28-a2ad-078230be180c':
      return CustomWhoWeArePage;
    case '92b1cc28-93fd-4ee9-94a1-5284d09d6273':
      return CustomRentalsPage;
    case 'f1f9ab43-f954-44e1-945b-f99bdf5139ca':
      return CustomPurchasePage;
    case '95217e8c-8cb5-45e5-a828-252531c2c973':
      return CustomHomePage;
    case 'bcbd5dd9-e735-4981-be04-e565b1533cda':
      return CustomBasicPage;
    case 'c8eaad29-48fc-42ce-ae47-084b63869aa7':
      return CustomHoneypot;
    case '56e88d7d-2ce3-4659-9015-3442deefb35f':
      return CustomEMailFormField;
    case '8ac11466-934a-4219-b127-53c4a791b7dd':
      return CustomDropdownField;
    case '05a8940c-d413-4f12-90bb-3ea6e2a6b240':
      return CustomTextFormField;
    case 'c13d0058-9fd6-475d-b344-de5dc725549f':
      return CustomDateInput;
    case '582c990d-f57f-4f72-a841-ff0ab7c125c6':
      return LilyPadPageComponent;
  }
  return undefined;
}
