<ngb-carousel *ngIf="post_collection" [wrap]="true">
    <ng-template ngbSlide *ngFor="let post of post_collection.posts | shuffle">
        <div class="testimonial-wrapper">
            <cms-template-section [post]="post" id="8d419c68-a20b-4025-9373-05321458c713"></cms-template-section>
            <h4>{{ post.name }}</h4>
            <ng-container *ngIf="post.properties as properties">
                <h5 class="mt-0" *ngIf="properties['company']?.value_character as value_character">{{ value_character }}</h5>
            </ng-container>
        </div>
    </ng-template>
</ngb-carousel>