import { Component, Inject } from '@angular/core';
import { POST_COLLECTION_MODEL } from '@murdough-solutions/cms-client';
import { CDNService, PostCollectionModel } from '@murdough-solutions/cms-common';

@Component({
  selector: 'CustomTestimonials',
  templateUrl: './testimonials.html',
  styleUrls: ['./testimonials.scss']
})
export class CustomTestimonials {
    
  constructor(
      @Inject(POST_COLLECTION_MODEL) public readonly post_collection: PostCollectionModel,
      public readonly cdn: CDNService
  ) {
      
  }

}
