<ng-container *ngIf="content$ | async as content">
    <div id="top-wrapper">
        <div class="container">
            <div id="top-content">
                <cms-template-section id="9bb0b2d0-6399-45a3-b47b-8dce1a953ee0"></cms-template-section>
            </div>
            
            <div class="row mt-4">
                <div id="primary-bucket" class="col-md-4 order-md-2">
                    <div class="card my-3">
                        <div class="card-body">
                            <cms-template-section id="4e642025-4d9d-4867-bf30-9a65f5d212e3"></cms-template-section>
                            <p class="text-center">
                                <a *ngIf="content.properties['middle_bucket_page']?.page as page" [routerLink]="page.route" class="btn btn-primary">View Details</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 order-md-1">
                    <div class="card my-3 mt-md-5">
                        <div class="card-body">
                            <cms-template-section id="1d3fb666-f4c8-4900-9544-1c509c3c97f9"></cms-template-section>
                            <p class="text-center">
                                <a *ngIf="content.properties['left_bucket_page']?.page as page" [routerLink]="page.route" class="btn btn-primary">View Details</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 order-md-3">
                    <div class="card my-3 mt-md-5">
                        <div class="card-body">
                            <cms-template-section id="505b5109-3bc9-45b1-8b02-f5623ce32a7d"></cms-template-section>
                            <p class="text-center">
                                <a *ngIf="content.properties['right_bucket_page']?.page as page" [routerLink]="page.route" class="btn btn-primary">View Details</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div id="middle-content" class="mt-4">
        <div class="container">
            <cms-template-section id="bd180821-aa2b-4b6f-b73c-b1cf870c2159"></cms-template-section>
        </div>
    </div>
    
    <div id="tx-wrapper" class="mt-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 pt-5 pb-5">
                    <cms-template-section id="7b056b6c-388e-49a7-8568-d9cdc672350a"></cms-template-section>
                </div>
                <div class="col-lg-7 d-none d-lg-block">
                    <img id="tx-wrapper-texas" src="/cdn/assets/20285924-e69c-4f0a-9d5e-4c310b589ec7.webp" class="img-fluid my-4" />
                </div>
            </div>
        </div>
    </div>
    
    <div id="lower-content" class="mt-5 mb-5">
        <div class="container">
            <cms-template-section id="0a480135-a9cc-4312-9343-97d2335b9c27"></cms-template-section>
        </div>
    </div>
</ng-container>