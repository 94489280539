<div id="top-wrapper">
  <div class="container">
    <div id="top-content">
      <cms-template-section
        id="172f35b2-07e1-493b-abc2-c7c4bc696d13"
      ></cms-template-section>
    </div>
  </div>
</div>
<div class="mt-4">
  <div class="container">
    <div id="main-content">
      <cms-template-section
        id="8dc6c28b-d57c-4309-a10c-2c28be23473a"
      ></cms-template-section>
    </div>

    <div
      id="stella-widget"
      class="stella-widget mb-3"
      data-company-id="66353f249d2d53784626bdd4"
      data-attribution-source-id="66707d5870247a6d8b10d1b2"
    >
      <h2 class="mt-4 text-center"><em>Loading Purchase Widget...</em></h2>
      <noscript
        ><div class="noscript">
          Lily Pad quoting widget requires JavaScript. If you are unable to run
          JavaScript on your device/s please use the contact form/page on this
          website.
        </div></noscript
      >
    </div>
  </div>
</div>
