<div class="form-group row">
    <label [for]="form_field.form_field_id" class="col-sm-3 col-form-label">{{ form_field.name }}</label>
    <div class="col-sm-9">
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd" [id]="form_field.form_field_id" [formControl]="control" [ngClass]="{ 'is-invalid': isInvalid }" ngbDatepicker #d="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><fa-icon [icon]="faCalendarAlt"></fa-icon></button>
          </div>
        </div>
    </div>
</div>