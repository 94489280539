import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FORM_COMPLETION_FIELD_MODEL } from '@murdough-solutions/cms-client';
import { FormCompletionFieldModel } from '@murdough-solutions/cms-common';

@Component({
  selector: 'CustomHoneypot',
  templateUrl: './honeypot.html',
  styleUrls: ['./honeypot.scss']
})
export class CustomHoneypot  {

    constructor(
        @Inject(FORM_COMPLETION_FIELD_MODEL) public readonly form_field: FormCompletionFieldModel, 
        public readonly control: FormControl
    ) {
      
    }

    public get isInvalid(): boolean {
        return this.control.touched && this.control.errors != null;
    }
}
