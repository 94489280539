import {
    HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';
import { NgPipesModule } from 'ngx-pipes';

import { FormService } from './services/form.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { MetaService } from './services/meta.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentResolver } from './resolvers/content.resolver';
import { PostCategoryResolver } from './resolvers/post-category.resolver';
import { PostCollectionResolver } from './resolvers/post-collection.resolver';
import { PostResolver } from './resolvers/post.resolver';

import { CmsFormFieldComponent } from './components/cms-form-field/cms-form-field.component';
import { CmsFormComponent } from './components/cms-form/cms-form.component';
import { CmsLoadingOverlayComponent } from './components/cms-loading-overlay/cms-loading-overlay.component';

import { CmsClientModule } from '@murdough-solutions/cms-client';
import { CmsCommonModule } from '@murdough-solutions/cms-common';
import { environment } from 'src/environments/environment';
import { CustomBasicPage } from './custom-components/basic-page/basic-page';
import { CustomContactForm } from './custom-components/contact-form/contact-form';
import { CustomDateInput } from './custom-components/date-input/date-input';
import { CustomDropdownField } from './custom-components/dropdown-field/dropdown-field';
import { CustomEMailFormField } from './custom-components/e-mail-form-field/e-mail-form-field';
import { CustomFooterMenu } from './custom-components/footer-menu/footer-menu';
import { CustomHomePage } from './custom-components/home-page/home-page';
import { CustomHoneypot } from './custom-components/honeypot/honeypot';
import { CustomNumericFormField } from './custom-components/numeric-form-field/numeric-form-field';
import { CustomPurchasePage } from './custom-components/purchase-page/purchase-page';
import { CustomRentalsPage } from './custom-components/rentals-page/rentals-page';
import { CustomTestimonials } from './custom-components/testimonials/testimonials';
import { CustomTextFormField } from './custom-components/text-form-field/text-form-field';
import { CustomTextareaFormField } from './custom-components/textarea-form-field/textarea-form-field';
import { CustomWhoWeArePage } from './custom-components/who-we-are-page/who-we-are-page';
import { LilyPadPageComponent } from './custom-components/lily-pad-page/lily-pad-page.component';

const isPrerender = window.navigator.userAgent?.includes(
  'Murdough Solutions CMS'
);

@NgModule({
  declarations: [
    AppComponent,
    CmsLoadingOverlayComponent,
    CmsFormComponent,
    CmsFormFieldComponent,
    CustomTestimonials,
    CustomContactForm,
    CustomTextareaFormField,
    CustomNumericFormField,
    CustomFooterMenu,
    CustomWhoWeArePage,
    CustomRentalsPage,
    CustomPurchasePage,
    CustomHomePage,
    CustomBasicPage,
    CustomHoneypot,
    CustomEMailFormField,
    CustomDropdownField,
    CustomTextFormField,
    CustomDateInput,
    LilyPadPageComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgPipesModule,
    NgbModule,
    FontAwesomeModule,
    Angulartics2Module.forRoot({
      developerMode: !environment.production || isPrerender,
    }),
    CmsCommonModule.forRoot({
      environment: environment.name,
      service_base: environment.service_base,
      cdn_base: environment.cdn_base,
      default_date_format: 'PPP',
      default_date_time_format: 'PPP, p',
    }),
    CmsClientModule.forRoot({
      environment: environment.name,
      service_base: environment.service_base,
    }),
    AppRoutingModule,
  ],
  providers: [
    GoogleTagManagerService,
    MetaService,
    FormService,
    ContentResolver,
    PostCollectionResolver,
    PostCategoryResolver,
    PostResolver,
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeAdapter,
    }
  ],
})
export class AppModule {}
