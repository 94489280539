import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';

@Component({
  selector: 'app-lily-pad-page',
  templateUrl: './lily-pad-page.component.html',
  styleUrl: './lily-pad-page.component.scss',
})
export class LilyPadPageComponent implements AfterViewInit {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit(): void {
    const css = this.document.createElement('link');
    css.rel = 'stylesheet';
    css.href = 'https://app.runstella.com/widget/index.css';
    this.document.head.appendChild(css);

    const script = this.document.createElement('script');
    script.src = 'https://app.runstella.com/widget/index.js';
    this.document.head.appendChild(script);
  }
}
