<form *ngIf="!form_completion" [formGroup]="form_group" (ngSubmit)="submit()">
    <cms-form-field *ngFor="let field of form.fields | orderBy: 'position'" [form_field]="field"  [formControlName]="field.form_field_id"></cms-form-field>
    <div class="form-group row">
        <div class="col-sm-9 offset-sm-3">
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
    </div>
</form>


<div *ngIf="state == FormState.Success" class="alert alert-success mt-3" role="alert">
    Request Submitted!
</div>
<div *ngIf="state == FormState.Invalid" class="alert alert-danger mt-3" role="alert">
    Please complete all required fields.
</div>
<div *ngIf="state == FormState.Error && error_message" class="alert alert-danger mt-3" role="alert">
    {{ error_message }}
</div>