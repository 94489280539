import { Component, Inject } from '@angular/core';
import { MENU_MODEL } from '@murdough-solutions/cms-client';
import { MenuModel, WEBSITE_DOMAIN, WebsiteDomainModel } from '@murdough-solutions/cms-common';

@Component({
  selector: 'CustomFooterMenu',
  templateUrl: './footer-menu.html',
  styleUrls: ['./footer-menu.scss']
})
export class CustomFooterMenu {
    
  constructor(
    @Inject(MENU_MODEL) public readonly menu: MenuModel,
    @Inject(WEBSITE_DOMAIN) public readonly domain: WebsiteDomainModel,
  ) {
      
  }

}
