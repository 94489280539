<div id="top-wrapper">
  <div class="container">
    <div id="top-content">
      <cms-template-section
        id="d303c282-88bd-4189-8bc0-71b834b10554"
      ></cms-template-section>
    </div>
  </div>
</div>
<div class="mt-4">
  <div class="container">
    <div id="main-content">
      <cms-template-section
        id="71c3bb07-095e-4010-8b90-561ccd435a38"
      ></cms-template-section>
    </div>

    <div
      id="stella-widget"
      class="stella-widget mb-3"
      data-company-id="66353f249d2d53784626bdd4"
      data-attribution-source-id="66707d5870247a6d8b10d1b2"
    >
        <h2 class="mt-4 text-center"><em>Loading Rental Widget...</em></h2>
      <noscript
        ><div class="noscript">
          Lily Pad quoting widget requires JavaScript. If you are unable to run
          JavaScript on your device/s please use the contact form/page on this
          website.
        </div></noscript
      >
    </div>

    <cms-template-section
    id="845b43c4-909c-4577-981c-aa3bf263e727"
  ></cms-template-section>
    <!--         
        <div class="row">
            <div class="col-md-7">
                <cms-template-section id="068f742f-8e5a-4845-a395-c2184b8bd5d4"></cms-template-section>
                <cms-form id="b3c02ea1-d78a-4394-bc00-5544f6741fb7"></cms-form>
            </div>
            <div class="col-md-5">
                <cms-template-section id="779bd995-fa28-4961-b3c5-cfd8c21c49a5"></cms-template-section>
            </div>
        </div> -->
  </div>
</div>
