import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CDNService, PostCategoryModel } from '@murdough-solutions/cms-common';
import { Observable } from 'rxjs';

@Injectable()
export class PostCategoryResolver  {

    constructor(private cdn: CDNService)
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<PostCategoryModel> {
        return this.cdn.GetPostCategory(route.data.post_category_id);
    }
}
