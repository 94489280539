import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FORM_COMPLETION_FIELD_MODEL } from '@murdough-solutions/cms-client';
import { FormCompletionFieldModel } from '@murdough-solutions/cms-common';

@Component({
  selector: 'CustomDateInput',
  templateUrl: './date-input.html',
  styleUrls: ['./date-input.scss']
})
export class CustomDateInput  {

	faCalendarAlt = faCalendarAlt;

    constructor(
        @Inject(FORM_COMPLETION_FIELD_MODEL) public readonly form_field: FormCompletionFieldModel, 
        public readonly control: FormControl
    ) {
      
    }

    public get isInvalid(): boolean {
        return this.control.touched && this.control.errors != null;
    }
}
