import { Component, forwardRef, Host, Injector, Input, OnInit, Optional, SkipSelf, Type } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { FORM_COMPLETION_FIELD_MODEL } from '@murdough-solutions/cms-client';
import { FormCompletionFieldModel } from '@murdough-solutions/cms-common';
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import { component_resolver } from 'src/app/custom-components/component-map';

interface FormFieldInit {
  component: Type<any>
  injector: Injector
}

@Component({
  selector: 'cms-form-field',
  templateUrl: './cms-form-field.component.html',
  styleUrls: ['./cms-form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmsFormFieldComponent),
      multi: true
    }
  ]
})
export class CmsFormFieldComponent implements OnInit, ControlValueAccessor {

  @Input() formControlName?: string;

  private readonly disabled_subject = new BehaviorSubject<boolean>(false)

  @Input() public set disable(disabled: boolean) {
    this.disabled_subject.next(disabled)
  }

  private readonly form_field_subject = new ReplaySubject<FormCompletionFieldModel>()

  @Input() public set form_field(form_field: FormCompletionFieldModel) {
    this.form_field_subject.next(form_field)
  }

  private readonly control_subject = new ReplaySubject<AbstractControl>()

  public readonly control$: Observable<UntypedFormControl> = this.control_subject.pipe(
    map(control => control as UntypedFormControl)
  )



  public readonly init$: Observable<FormFieldInit> = combineLatest([this.form_field_subject, this.control$, this.disabled_subject]).pipe(
    map(([form_field, control, disabled]) => {
      if (disabled || form_field.disable) {
        control.disable();
      }

      const component = component_resolver(form_field.template_id)
      if (!component) {
        throw new Error(`Component not found for Template ID: ${form_field.template_id}`)
      }

      return {
        injector: Injector.create({
          providers: [
            { provide: FORM_COMPLETION_FIELD_MODEL, useValue: form_field },
            { provide: UntypedFormControl, useValue: control }
          ],
          parent: this.injector
        }),
        component
      }
    })
  )

  constructor(
    @Optional() @Host() @SkipSelf() private readonly control_container: ControlContainer,
    private readonly injector: Injector
  ) {

  }

  ngOnInit(): void {
    if (this.control_container && this.formControlName) {
      const control = this.control_container.control?.get(this.formControlName)
      if (control) {
        this.control_subject.next(control)
      }
    }
  }

  public writeValue(value: any): void {
  }

  registerOnChange(fn: (value: any) => {}): void {

  }

  registerOnTouched(fn: () => {}): void {

  }


}
